<template>
  <div class="content">
    <div class="card" style="display: flex">
      <div class="middle">
        <Cond
          @onCond="handleCond"
          @onReset="handleReset"
          function_code="searchGoodsList"
        />
      </div>
      <div class="right" style="flex-shrink: 0; display: flex">
        <TableConf
          :function_code="function_code"
          @onRowList="handleRowList"
          :mode="mode"
          :tab="tab"
          :show="false"
          :noWatch="noWatch"
        />
      </div>
    </div>
    <el-radio-group
      v-model="currTab"
      style="margin: 10px"
      @change="handleChange"
    >
      <template v-for="item in tabs">
        <el-radio-button :label="item.label" :key="item.label">{{
          item.txt
        }}</el-radio-button>
      </template>
    </el-radio-group>
    <div
      class="table"
      style="border-top: 1px solid #ddd; height: 640px"
      v-loading="loading"
    >
      <u-table
        v-if="rowList.length && table === 'table'"
        :data="tableData.list"
        stripe
        :border="false"
        height="640"
        @row-dblclick="handleDalclick($event, currTab)"
      >
        <u-table-column :type="tableType" v-if="tableType === 'expand'">
          <template
            slot-scope="scope"
            v-if="scope.row.goods_number_type === 20"
          >
            <div style="display: flex">
              <div
                class="table-expand"
                v-for="(item, index) in scope.row.mutile_goods"
                :key="index"
              >
                <div>
                  {{ item.status_name }}
                  <span style="margin-left: 5px">{{ item.goods_pcs }}</span>
                </div>
              </div>
            </div>
          </template>
        </u-table-column>
        <template v-for="(item, index) in rowList">
          <u-table-column
            v-if="item.filed_status"
            align="center"
            :key="index"
            :prop="item.field_alias"
            :label="item.field_text"
            :width="item.t_width"
          >
          </u-table-column>
          <u-table-column
            v-else-if="item.field_alias === 'goods_name'"
            :key="index"
            :prop="item.field_alias"
            :label="item.field_text"
            :width="item.t_width"
          >
            <template slot-scope="scope">
              <div style="display: inline-block">
                {{ scope.row[item.field_alias] || "--" }}
                <template v-for="item in scope.row.goods_tag_pic">
                  <img
                    :src="item"
                    :key="item"
                    style="margin-left: 5px; vertical-align: middle"
                  />
                </template>
              </div>
            </template>
          </u-table-column>
          <u-table-column
            v-else
            align="center"
            :key="index"
            :prop="item.field_alias"
            :label="item.field_text"
            :width="item.t_width"
          >
            <template slot-scope="scope">
              <div v-if="item.field_alias === 'goods_pic'">
                <img
                  :src="scope.row.goods_pic"
                  v-if="scope.row.goods_pic"
                  class="image-slot"
                />
                <div slot="error" class="image-slot" v-else>
                  <!-- <img src="../../../../../assets/images/no_img.png" /> -->
                  <img :src="noImg" />
                </div>
              </div>
              <div v-else-if="item.field_alias === 'warning_state'">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="警报等级依次为：绿<黄<橙<红"
                  placement="top-start"
                  v-if="
                    scope.row[item.field_alias] !== 10 &&
                    scope.row[item.field_alias] !== 20 &&
                    scope.row[item.field_alias] !== 30
                  "
                >
                  <div
                    style="
                      background-color: #21bc36;
                      width: 12px;
                      height: 12px;
                      border-radius: 50%;
                      margin: 0 auto;
                    "
                  ></div>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="警报等级依次为：绿<黄<橙<红"
                  placement="top-start"
                  v-else-if="scope.row[item.field_alias] === 10"
                >
                  <div
                    style="
                      background-color: #ffdf04;
                      width: 12px;
                      height: 12px;
                      border-radius: 50%;
                      margin: 0 auto;
                    "
                  ></div>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="警报等级依次为：绿<黄<橙<红"
                  placement="top-start"
                  v-else-if="scope.row[item.field_alias] === 20"
                >
                  <div
                    style="
                      background-color: #ff9000;
                      width: 12px;
                      height: 12px;
                      border-radius: 50%;
                      margin: 0 auto;
                    "
                  ></div>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="警报等级依次为：绿<黄<橙<红"
                  placement="top-start"
                  v-else-if="scope.row[item.field_alias] === 30"
                >
                  <div
                    style="
                      background-color: #f4390d;
                      width: 12px;
                      height: 12px;
                      border-radius: 50%;
                      margin: 0 auto;
                    "
                  ></div>
                </el-tooltip>
              </div>
              <div v-else>
                {{ scope.row[item.field_alias] || "--" }}
              </div>
            </template>
          </u-table-column>
        </template>
        <u-table-column
          align="center"
          prop="operation"
          fixed="right"
          label="操作"
          width="180"
        >
          <template slot-scope="scope">
            <div class="options">
              <el-button type="text" @click="toDetail(scope.row, currTab)">详情</el-button>
            </div>
          </template>
        </u-table-column>
      </u-table>
      <el-table
        v-if="rowList.length && table === 'orderListTable'"
        @row-dblclick="handleDalclick($event, currTab)"
        height="640"
        :data="tableData.list"
        style="width: 100%"
        border
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  item.field_alias !== 'goods' &&
                  item.field_alias !== 'member' &&
                  item.field_alias !== 'sale_price' &&
                  item.field_alias !== 'goods_pcs'
                "
              >
                <span
                  style="color: #f56c6c"
                  v-if="item.field_alias === 'order_number'"
                >
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </span>
                <span v-else>
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </span>
              </template>
              <template v-if="item.field_alias === 'goods'">
                <template v-for="(good, goodIndex) in scope.row.goods">
                  <div
                    style="display: flex; padding: 10px"
                    :key="good.order_goods_id"
                    :class="
                      goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                    "
                  >
                    <div>
                      <img
                        :src="good.goods_pic"
                        style="width: 80px; height: 80px; object-fit: contain"
                        v-if="good.goods_pic"
                      />
                      <img
                        :src="noImg"
                        style="width: 80px; height: 80px"
                        v-else
                      />
                    </div>
                    <div style="margin-left: 15px; text-align: left">
                      <div>品名：{{ good.goods_name }}</div>
                      <div>品类：{{ good.goods_type_name }}</div>
                      <div>货号：{{ good.goods_number }}</div>
                      <!-- <div>
                          <el-tag v-if="scope.row.isRaw" type="warning">旧料</el-tag>
                        </div> -->
                      <div style="display: flex">
                        <template v-for="item in good.goods_tag_pic">
                          <img
                            :src="item"
                            :key="item"
                            style="margin-right: 5px"
                          />
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="item.field_alias === 'sale_price'">
                <template v-for="(good, goodIndex) in scope.row.sale_price">
                  <div
                    :key="goodIndex"
                    :class="
                      goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                    "
                    style="
                      height: 107px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ good }}
                  </div>
                </template>
              </template>
              <template v-if="item.field_alias === 'goods_pcs'">
                <template v-for="(good, goodIndex) in scope.row.goods_pcs">
                  <div
                    :key="goodIndex"
                    :class="
                      goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                    "
                    style="
                      height: 107px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ good }}
                  </div>
                </template>
              </template>
              <template v-if="item.field_alias === 'member'">
                <div>{{ scope.row.member_name || "--" }}</div>
                <div>{{ scope.row.member_phone || "--" }}</div>
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          align="center"
          fixed="right"
          field_alias="operation"
          label="操作"
          width="180"
        >
          <template slot-scope="scope">
            <div class="options">
              <el-button type="text" @click="toDetail(scope.row, currTab)">详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-if="rowList.length && table === 'refunOrderTable'"
        @row-dblclick="handleDalclick($event, currTab)"
        :data="tableData.list"
        style="width: 100%"
        height="640"
        border
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  item.field_alias !== 'goods' &&
                  item.field_alias !== 'member' &&
                  item.field_alias !== 'refund_price' &&
                  item.field_alias !== 'sale_price' &&
                  item.field_alias !== 'num'
                "
              >
                <span
                  style="color: #f56c6c"
                  v-if="item.field_alias === 'refund_order_number'"
                >
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </span>
                <span v-else>
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </span>
              </template>
              <template v-if="item.field_alias === 'goods'">
                <template v-for="(good, goodIndex) in scope.row.goods">
                  <div
                    style="display: flex; padding: 10px"
                    :key="good.order_goods_id"
                    :class="
                      goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                    "
                  >
                    <div>
                      <img
                        :src="good.goods_pic"
                        style="width: 80px; height: 80px; object-fit: contain"
                        v-if="good.goods_pic"
                      />
                      <img
                        :src="noImg"
                        style="width: 80px; height: 80px"
                        v-else
                      />
                    </div>
                    <div style="margin-left: 15px; text-align: left">
                      <div>品名：{{ good.goods_name }}</div>
                      <div>品类：{{ good.goods_type_name }}</div>
                      <div>货号：{{ good.goods_number }}</div>
                      <!-- <div>
                          <el-tag v-if="scope.row.isRaw" type="warning">旧料</el-tag>
                        </div> -->
                      <div style="display: flex">
                        <template v-for="item in good.goods_tag_pic">
                          <img
                            :src="item"
                            :key="item"
                            style="margin-right: 5px"
                          />
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="item.field_alias === 'refund_price'">
                <template v-for="(good, goodIndex) in scope.row.refund_price">
                  <div
                    :key="goodIndex"
                    :class="
                      goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                    "
                    style="
                      height: 107px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ good }}
                  </div>
                </template>
              </template>
              <template v-if="item.field_alias === 'sale_price'">
                <template v-for="(good, goodIndex) in scope.row.sale_price">
                  <div
                    :key="goodIndex"
                    :class="
                      goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                    "
                    style="
                      height: 107px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ good }}
                  </div>
                </template>
              </template>
              <template v-if="item.field_alias === 'num'">
                <template v-for="(good, goodIndex) in scope.row.num">
                  <div
                    :key="goodIndex"
                    :class="
                      goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                    "
                    style="
                      height: 107px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ good }}
                  </div>
                </template>
              </template>
              <template v-if="item.field_alias === 'member'">
                <div>{{ scope.row.member_name || "--" }}</div>
                <div>{{ scope.row.member_phone || "--" }}</div>
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          align="center"
          fixed="right"
          field_alias="operation"
          label="操作"
          width="180"
        >
          <template slot-scope="scope">
            <div class="options">
              <el-button type="text" @click="toDetail(scope.row, currTab)">详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-if="rowList.length && table === 'recycleOrderTable'"
        @row-dblclick="handleDalclick($event, currTab)"
        :data="tableData.list"
        style="width: 100%"
        height="640"
        border
      >
        <!-- <el-table-column
            type="selection"
            align="center"
            width="55">
          </el-table-column> -->
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  item.field_alias !== 'goods' &&
                  item.field_alias !== 'member' &&
                  item.field_alias !== 'material_name' &&
                  item.field_alias !== 'goods_pcs' &&
                  item.field_alias !== 'goldweight'
                "
              >
                <span
                  style="color: #f56c6c"
                  v-if="item.field_alias === 'refund_order_number'"
                >
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </span>
                <span v-else>
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </span>
              </template>
              <template v-if="item.field_alias === 'goods'">
                <template v-for="(good, goodIndex) in scope.row.goods">
                  <div
                    style="display: flex; padding: 10px"
                    :key="good.order_goods_id"
                    :class="
                      goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                    "
                  >
                    <div>
                      <img
                        :src="good.goods_pic"
                        style="width: 80px; height: 80px; object-fit: contain"
                        v-if="good.goods_pic"
                      />
                      <img
                        :src="noImg"
                        style="width: 80px; height: 80px"
                        v-else
                      />
                    </div>
                    <div style="margin-left: 15px; text-align: left">
                      <div>品名：{{ good.goods_name }}</div>
                      <div>品类：{{ good.goods_type_name }}</div>
                      <div>货号：{{ good.goods_number }}</div>
                      <!-- <div>
                          <el-tag v-if="scope.row.isRaw" type="warning">旧料</el-tag>
                        </div> -->
                      <div style="display: flex">
                        <template v-for="item in good.goods_tag_pic">
                          <img
                            :src="item"
                            :key="item"
                            style="margin-right: 5px"
                          />
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="item.field_alias === 'material_name'">
                <template v-for="(good, goodIndex) in scope.row.material_name">
                  <div
                    :key="goodIndex"
                    :class="
                      goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                    "
                    style="
                      height: 107px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ good }}
                  </div>
                </template>
              </template>
              <template v-if="item.field_alias === 'goods_pcs'">
                <template v-for="(good, goodIndex) in scope.row.goods_pcs">
                  <div
                    :key="goodIndex"
                    :class="
                      goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                    "
                    style="
                      height: 107px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ good }}
                  </div>
                </template>
              </template>
              <template v-if="item.field_alias === 'goldweight'">
                <template v-for="(good, goodIndex) in scope.row.goldweight">
                  <div
                    :key="goodIndex"
                    :class="
                      goodIndex + 1 !== scope.row.goods.length ? 'border' : ''
                    "
                    style="
                      height: 107px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ good }}
                  </div>
                </template>
              </template>
              <template v-if="item.field_alias === 'member'">
                <div>{{ scope.row.member_name || "--" }}</div>
                <div>{{ scope.row.member_phone || "--" }}</div>
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          align="center"
          fixed="right"
          field_alias="operation"
          label="操作"
          width="180"
        >
          <template slot-scope="scope">
            <div class="options">
              <el-button type="text" @click="toDetail(scope.row, currTab)">详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-table
          v-if="rowList.length && table === 'redemptionOrderTable'"
          @row-dblclick="handleDalclick($event, currTab)"
          :data="tableData.list"
          style="width: 100%"
          border
          height="640"
        >
          <template v-for="item in rowList">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              align="center"
              :width="item.t_width">
              <template slot-scope="scope">
                <template v-if="item.field_alias !== 'goods' && item.field_alias !== 'member' && item.field_alias !== 'num' && item.field_alias !== 'recycle_price'">
                  <span style="color: #F56C6C;" v-if="item.field_alias === 'refund_order_number'">
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                  </span>
                  <span v-else>
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                  </span>
                </template>
                <template v-if="item.field_alias === 'goods'">
                  <template v-for="(good, goodIndex) in scope.row.goods">
                    <div style="display: flex; padding: 10px;" :key="good.order_goods_id" :class="(goodIndex + 1) !== scope.row.goods.length? 'border': ''">
                      <div>
                        <img :src="good.goods_pic" style="width: 80px; height: 80px; object-fit: contain;" v-if="good.goods_pic" />
                        <img :src="noImg" style="width: 80px; height: 80px;" v-else />
                      </div>
                      <div style="margin-left: 15px; text-align: left;">
                        <div>品名：{{good.goods_name}}</div>
                        <div>品类：{{good.goods_type_name}}</div>
                        <div>货号：{{good.goods_number}}</div>
                        <!-- <div>
                          <el-tag v-if="scope.row.isRaw" type="warning">旧料</el-tag>
                        </div> -->
                        <div style="display: flex;">
                          <template v-for="item in good.goods_tag_pic">
                            <img :src="item" :key="item" style="margin-right: 5px;" />
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'recycle_price'">
                  <template v-for="(good, goodIndex) in scope.row.recycle_price">
                    <div :key="goodIndex" :class="(goodIndex + 1) !== scope.row.goods.length? 'border': ''"
                    style="height: 107px; display: flex; justify-content: center; align-items: center;">
                      {{good}}
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'num'">
                  <template v-for="(good, goodIndex) in scope.row.num">
                    <div :key="goodIndex" :class="(goodIndex + 1) !== scope.row.goods.length? 'border': ''"
                    style="height: 107px; display: flex; justify-content: center; align-items: center;">
                      {{good}}
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'member'">
                  <div>{{scope.row.member_name || '--'}}</div>
                  <div>{{scope.row.member_phone || '--'}}</div>
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align='center'
            fixed='right'
            field_alias='operation'
            label='操作'
            width="180"
          >
            <template slot-scope="scope">
            <div class="options">
              <el-button type="text" @click="toDetail(scope.row, currTab)">详情</el-button>
            </div>
          </template>
          </el-table-column>
      </el-table>
    </div>
    <div class="pagina" v-if="rowList.length">
      <el-pagination
        :current-page="page"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
        :page-size="$store.state.pageSizes[0]"
        :page-sizes="$store.state.pageSizes"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import Cond from "@/components/cond/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import {
  getIndexReq,
  getEntryListReq,
  getTakeListReq,
  getAmendListReq,
  getDestroyListReq,
  getAllotListReq,
  getSplitListReq,
  getOrderListReq,
  getRefundListReq,
  getRecycleListReq,
  getRedemptionListReq,
  getAllotWarehouseListReq,
} from "@/api/goods/goodsSearch";

export default {
  name: "NewGoodsSearch",
  data() {
    const orderRows = [
      // { field_text: "序号", field_alias: "index" },
      { field_text: "订单号", field_alias: "order_number", t_width: 250 },
      { field_text: "下单时间", field_alias: "create_time", t_width: 200 },
      { field_text: "货品", field_alias: "goods", t_width: 350 },
      { field_text: "标签价", field_alias: "sale_price", t_width: 120 },
      { field_text: "数量", field_alias: "goods_pcs", t_width: 120 },
      // { field_text: '订单类型', field_alias: 'type' },
      { field_text: "会员", field_alias: "member", t_width: 120 },
      { field_text: "主销售", field_alias: "main_store_name", t_width: 120 },
      { field_text: "辅销售", field_alias: "vice_store_name", t_width: 120 },
      {
        field_text: "订单状态",
        field_alias: "order_status_text",
        t_width: 120,
      },
      {
        field_text: "旧料金额",
        field_alias: "order_raw_amount",
        t_width: 120,
      },
      { field_text: "优惠金额", field_alias: "order_discount", t_width: 120 },
      { field_text: "实收金额", field_alias: "paid_in_amount", t_width: 120 },
      { field_text: "所属门店", field_alias: "hierarchy_name", t_width: 120 },
    ];
    const refundOrderRows = [
      // { field_text: '序号', field_alias: 'index' },
      {
        field_text: "退货单号",
        field_alias: "refund_order_number",
        t_width: 250,
      },
      { field_text: "原订单号", field_alias: "order_number", t_width: 250 },
      { field_text: "退款时间", field_alias: "create_time", t_width: 200 },
      { field_text: "退货货品", field_alias: "goods", t_width: 300 },
      { field_text: "应退金额", field_alias: "refund_price", t_width: 120 },
      { field_text: "实退金额", field_alias: "sale_price", t_width: 120 },
      { field_text: "数量", field_alias: "num", t_width: 120 },
      { field_text: "会员", field_alias: "member", t_width: 120 },
      { field_text: "经办人", field_alias: "store_user_name", t_width: 120 },
      {
        field_text: "主销售",
        field_alias: "main_store_user_name",
        t_width: 120,
      },
      {
        field_text: "辅销售",
        field_alias: "vice_store_user_name",
        t_width: 120,
      },
      { field_text: "退还积分", field_alias: "return_inegral", t_width: 120 },
      { field_text: "所属门店", field_alias: "hierarchy_name", t_width: 120 },
    ];
    const recycleOrderRows = [
      // { field_text: '序号', field_alias: 'index' },
      {
        field_text: "回收单号",
        field_alias: "recycle_order_number",
        t_width: 250,
      },
      { field_text: "原订单号", field_alias: "order_number", t_width: 250 },
      { field_text: "货品", field_alias: "goods", t_width: 300 },
      { field_text: "材质成色", field_alias: "material_name", t_width: 120 },
      { field_text: "数量", field_alias: "goods_pcs", t_width: 120 },
      { field_text: "净金重", field_alias: "goldweight", t_width: 120 },
      { field_text: "主销售", field_alias: "main_store_name", t_width: 120 },
      { field_text: "辅销售", field_alias: "vice_store_name", t_width: 120 },
      { field_text: "回收金额", field_alias: "recycle_amount", t_width: 120 },
      { field_text: "会员", field_alias: "member", t_width: 120 },
      { field_text: "回收时间", field_alias: "create_time", t_width: 200 },
      { field_text: "所属门店", field_alias: "hierarchy_name", t_width: 120 },
    ];
    const redemptionOrderRows = [
      // { field_text: '序号', field_alias: 'index' },
      {
        field_text: "赎回单号",
        field_alias: "redemption_order_number",
        t_width: 250,
      },
      {
        field_text: "回收订单号",
        field_alias: "recycle_order_number",
        t_width: 250,
      },
      { field_text: "赎回货品", field_alias: "goods", t_width: 300 },
      { field_text: "数量", field_alias: "num", t_width: 120 },
      { field_text: "回收价", field_alias: "recycle_price", t_width: 120 },
      { field_text: "会员", field_alias: "member", t_width: 120 },
      { field_text: "主销售", field_alias: "store_user_name", t_width: 120 },
      { field_text: "赎回金额", field_alias: "paid_in_amount", t_width: 120 },
      { field_text: "赎回时间", field_alias: "create_time", t_width: 200 },
      { field_text: "所属门店", field_alias: "hierarchy_name", t_width: 120 },
    ];
    return {
      table: "table",
      reset: false,
      tip: false,
      noWatch: false,
      tab: 0,
      mode: 0,
      tableType: "expand",
      function_code: "goodsList", // 表头字段
      // 销售单表头
      tabs: [
        {
          txt: "货品",
          label: 0,
          function_code: "goodsList",
          mode: 0,
          table: "table",
        },
        {
          txt: "入库",
          label: 1,
          function_code: "entry",
          mode: 0,
          table: "table",
        },
        {
          txt: "盘点",
          label: 2,
          function_code: "check",
          mode: 0,
          table: "table",
        },
        {
          txt: "修改",
          label: 3,
          function_code: "edit",
          mode: 1,
          table: "table",
        },
        {
          txt: "退库",
          label: 4,
          function_code: "cancel",
          mode: 1,
          table: "table",
        },
        {
          txt: "调仓",
          label: 11,
          function_code: "allot_warehouse",
          mode: 1,
          tab: 0,
          table: "table",
        },
        {
          txt: "调拨",
          label: 5,
          function_code: "allot",
          mode: 1,
          tab: 1,
          table: "table",
        },
        {
          txt: "拆改",
          label: 6,
          function_code: "split",
          mode: 1,
          table: "table",
        },
        {
          txt: "销售订单",
          label: 7,
          function_code: "orderList",
          mode: 0,
          rows: orderRows,
          table: "orderListTable",
        },
        {
          txt: "退货订单",
          label: 8,
          function_code: "refund",
          mode: 0,
          rows: refundOrderRows,
          table: "refunOrderTable",
        },
        {
          txt: "回收订单",
          label: 9,
          function_code: "recycleList",
          mode: 0,
          rows: recycleOrderRows,
          table: "recycleOrderTable",
        },
        {
          txt: "赎回订单",
          label: 10,
          function_code: "redemption",
          mode: 0,
          rows: redemptionOrderRows,
          table: "redemptionOrderTable",
        },
      ],
      loading: false,
      cond: {}, // 条件
      currTab: 0,
      rowList: [],
      noImg: require("@/assets/images/no_img.png"),
      tableData: {
        list: [],
        total: 0,
      },
      page: 1,
      limit: this.$store.state.pageSizes[0],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 跳转详情
    toDetail(row, currTab) {
      switch (currTab) {
        case 0:
          // 货品（分新品和旧料)
          if (Number(row.recycle_price)) {
            // 是旧料
            this.$router.push({
              name: 'GoodsOldDetailed',
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key: row.goods_id,
              },
            });
          } else {
            // 非旧料
            this.$router.push({
              name: 'GoodsDetailed',
              query: {
                t: Date.now(),
              },
              params: {
                type: 'detail',
                key: row.goods_id,
              },
            });
          }
          break;
        case 1:
          // 入库
          console.log(row)
          this.$router.push({
            name:'godownEntryList',
            params:{entry_number: row.entry_number},
            // name: 'WareHousingList',
            // query: {
            //   t: Date.now(),
            // },
            // params: {
            //   type: 'detail',
            //   key: row.entry_id,
            // },
            
          });
          break;
        case 2:
          // 盘点
          this.$router.push({
            name: 'InventoryList',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.check_id,
            },
          });
          break;
        case 3:
          // 修改
          this.$router.push({
            name: 'ModifyList',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.edit_id,
              goodsType: row.goods_type_id,
            },
          });
          break;
        case 4:
          // 销库
          this.$router.push({
            name: 'StockList',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.cancel_id,
            },
          });
          break;
        case 5:
          // 调拨
          this.$router.push({
            name: 'TransferOut',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.allot_id,
            },
          });
          break;
        case 6:
          // 拆改
          this.$router.push({
            name: 'OldList',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.split_id,
            },
          });
          break;
        case 7:
          // 销售单
          this.$router.push({
            name: 'offlineOrderSalesOrder',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.order_id,
            },
          });
          break;
        case 8:
          // 退货单
          this.$router.push({
            name: 'offlineOrderReturnOrder',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.refund_order_id,
            },
          });
          break;
        case 9:
          // 回收单
          this.$router.push({
            name: 'changeOrder',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.recycle_order_id,
            },
          });
          break;
        case 10:
          // 回收单
          this.$router.push({
            name: 'redeemOrder',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.redemption_order_id,
            },
          });
          break;
        case 11:
          //调仓
          this.$router.push({
            name: 'Adjustment',
            query: {
              t: Date.now(),
            },
            params: {
              type: 'detail',
              key: row.allot_warehouse_id,
            },
          });
          break;
        default:
          console.log('默认');
      }
    },
    // 点击tabs
    handleChange(index) {
      if (index === 0) {
        this.tableType = "expand";
      } else {
        this.tableType = "";
      }
      this.rowList = [];
      this.page = 1;
      const [currTab] = this.tabs.filter((item) => {
        return item.label === index;
      })
      // 重新获取表头
      // 销售单，退货单，回收单，赎回单表头是写死的；
      if (currTab.rows) {
        this.rowList = [];
        setTimeout(() => {
          this.$nextTick(() => {
            this.rowList = currTab.rows;
          });
        }, 500);
        this.noWatch = true;
        this.function_code = currTab.function_code;
      } else {
        this.noWatch = false;
        if (currTab.tab) {
          this.tab = currTab.tab;
        } else {
          this.tab = 0;
        }
        this.function_code = currTab.function_code;
        this.mode = currTab.mode;
      }
      this.table = currTab.table;
      // 重新获取列表
      this.getList();
      console.log(this.noWatch);
    },
    // 分页
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    // 改变每页条数
    handleSizeChange(limit) {
      this.limit = limit;
      this.page = 1;
      this.getList();
    },

    // 双击列表行
    handleDalclick(row, currTab) {
      this.toDetail(row, currTab);
    },
    // 获取列表数据
    getList() {
      const newObj = {};
      Object.keys(this.cond).forEach((key) => {
        if (this.cond[key]) {
          if (typeof this.cond[key] === "object") {
            if (this.cond[key].length !== 0) {
              newObj[key] = this.cond[key];
            }
          } else {
            newObj[key] = this.cond[key];
          }
        }
      });
      if (!Object.keys(newObj).length) {
        if (this.tip) {
          this.$message.warning("请填写搜索条件");
        }
        this.tip = false;
        return;
      }
      // 货品(包括旧料)
      if (this.currTab === 0) {
        this.loading = true;
        getIndexReq({
          ...this.cond,
          page: this.page,
          limit: this.limit,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.tableData = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
      // 入库
      if (this.currTab === 1) {
        this.loading = true;
        getEntryListReq({
          ...this.cond,
          page: this.page,
          limit: this.limit,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.tableData = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
      // 盘点
      if (this.currTab === 2) {
        this.loading = true;
        getTakeListReq({
          ...this.cond,
          page: this.page,
          limit: this.limit,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.tableData = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
      // 修改单
      if (this.currTab === 3) {
        this.loading = true;
        getAmendListReq({
          ...this.cond,
          page: this.page,
          limit: this.limit,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.tableData = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
      // 销库单
      if (this.currTab === 4) {
        this.loading = true;
        getDestroyListReq({
          ...this.cond,
          page: this.page,
          limit: this.limit,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.tableData = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
      // 调拨单
      if (this.currTab === 5) {
        this.loading = true;
        getAllotListReq({
          ...this.cond,
          page: this.page,
          limit: this.limit,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.tableData = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
      // 拆改单
      if (this.currTab === 6) {
        this.loading = true;
        getSplitListReq({
          ...this.cond,
          page: this.page,
          limit: this.limit,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.tableData = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
      // 销售单
      if (this.currTab === 7) {
        this.loading = true;
        getOrderListReq({
          ...this.cond,
          page: this.page,
          limit: this.limit,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              res.data.list.forEach((item, index) => {
                item.index = index + 1;
                item.sale_price = [];
                item.goods_pcs = [];
                item.goods.forEach((good) => {
                  item.sale_price.push(good.sale_price);
                  item.goods_pcs.push(good.goods_pcs);
                });
              });
              this.tableData = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
      // 退货单
      if (this.currTab === 8) {
        this.loading = true;
        getRefundListReq({
          ...this.cond,
          page: this.page,
          limit: this.limit,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              res.data.list.forEach((item, index) => {
                item.index = index + 1;
                item.refund_price = [];
                item.sale_price = [];
                item.num = [];
                item.goods.forEach((good) => {
                  item.refund_price.push(good.refund_price);
                  item.sale_price.push(good.sale_price);
                  item.num.push(good.num);
                });
              });
              this.tableData = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
      // 回收单
      if (this.currTab === 9) {
        this.loading = true;
        getRecycleListReq({
          ...this.cond,
          page: this.page,
          limit: this.limit,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              res.data.list.forEach((item, index) => {
                item.index = index + 1;
                item.material_name = [];
                item.goods_pcs = [];
                item.goldweight = [];
                item.goods.forEach((good) => {
                  item.material_name.push(good.material_name);
                  item.goods_pcs.push(good.goods_pcs);
                  item.goldweight.push(good.goldweight);
                });
              });
              console.log(this.tableData)
              this.tableData = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
      // 赎回单
      if (this.currTab === 10) {
        this.loading = true;
        getRedemptionListReq({
          ...this.cond,
          page: this.page,
          limit: this.limit,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              res.data.list.forEach((item, index) => {
                item.index = index + 1;
                item.recycle_price = [];
                item.num = [];
                item.goods.forEach((good) => {
                  item.recycle_price.push(good.recycle_price);
                  item.num.push(good.num);
                });
              });
              this.tableData = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
      // 调仓
      if (this.currTab === 11) {
        this.loading = true;
        getAllotWarehouseListReq({
          ...this.cond,
          page: this.page,
          limit: this.limit,
        })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.tableData = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    handleReset() {
      this.reset = true;
      this.tableData = {
        list: [],
        total: 0,
      };
    },
    // 搜索
    handleCond(cond) {
      this.cond = {
        ...cond,
      };
      this.page = 1;
      if (!this.reset) {
        this.tip = true;
      } else {
        this.reset = false;
      }
      this.getList();
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
      console.log(this.rowList);
    },
  },
  components: {
    Cond,
    TableConf,
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-table__row .cell {
  padding: 0;
}
.border {
  border-bottom: 1px solid #ddd;
}
/deep/ .el-table__fixed-right {
  background: white;
}
/deep/ .table-expand {
  width: 11.13%;
  color: #2791ff;
  text-align: center;
  border-right: 1px solid #ddd;
}
.image-slot {
  width: 50px;
  height: 50px;
  margin: 5px auto 5px auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
}
</style>
